import {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {listTrainingPrograms} from 'src/graphql/content';

/**
 * Returns all active trainingProgram
 * @return {object} an object with multiple trainingProgram
 */
export default function useTrainingPrograms() {
  const [trainingProgram, setTrainingProgram] = useState();

  useEffect(() => {
    if (!trainingProgram) {
      (async () => {
        const data = await API.graphql({
          query: listTrainingPrograms,
        });
        setTrainingProgram(data.data.listTrainingPrograms.items
            .sort((a, b) => (a.index > b.index) ? 1 : -1)); // Sort by index
      })();
    }
  });
  return (trainingProgram);
}
