import {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {listInitiatives} from 'src/graphql/content';

/**
 * Returns all active programs
 * @return {object} an object with multiple programs
 */
export default function usePrograms() {
  const [programs, setPrograms] = useState();

  useEffect(() => {
    if (!programs) {
      (async () => {
        const data = await API.graphql({
          query: listInitiatives,
        });
        setPrograms(data.data.listInitiatives.items
            .sort((a, b) => (a.index > b.index) ? 1 : -1)); // Sort by index
      })();
    }
  });
  return (programs);
}
