import React, {useEffect, useState} from 'react';

import Page from 'src/components/functional/page';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import FeaturedHeroSection
  from 'src/components/functional/hero-section-featured';
import {useLocation} from '@reach/router';
import {useFeatureFlags} from 'src/functionality/flags-provider';
import {usePageContent} from 'src/functionality/content-provider';
import usePrograms from 'src/functionality/programs-provider';
import {theme} from 'src/styles/theme';
import {breakpoints} from 'src/styles/breakpoints';
import useTrainingPrograms from 'src/functionality/training-provider';
import ArbiasButton from 'src/components/functional/arbias-button';
import {getResource} from 'src/functionality/resources-provider';
import SEO from 'src/components/seo';

import {Link} from 'gatsby';

import {Row, Col, Container} from 'react-bootstrap';

import Markdown from 'react-markdown';
import HeroImage from 'images/circles.jpg';
import AboriginalBG from 'images/abi-and-aboriginal-people.jpg';
import managingComplexities1 from 'images/what-we-do/managing-complexities-1.png';
import managingComplexities2 from 'images/what-we-do/managing-complexities-2.png';
import managingComplexities3 from 'images/what-we-do/managing-complexities-3.png';

import {H3, H5, H6} from 'src/components/functional/headings';
import Spacer from 'src/components/graphical/spacer';
import ContentBlock from 'src/components/functional/content-block';
import Tile from 'src/components/functional/tile';
import Strip from '../../components/functional/strip';

const ProgramsSection = styled.section`
  & img{
    max-height: 65vh;
  }
`;
const AwarenessSection = styled.section`
  background-color: ${theme.bodyBg};
  & img{
    max-height: 65vh;
  }
`;

const ResearchSection = styled.section``;

const OurHealthOurWaySection = styled.section`
  color: ${theme.light};
  padding: 10vh 1em;
  background-image:
    linear-gradient(
      rgba(0, 0, 0, 0.25), 
      rgba(0, 0, 0, 0.25)
    ),
    url(${AboriginalBG});
  background-size: cover;
  background-position: bottom center;
  & .container{
    display: flex;
  }
  @media ${breakpoints.md} {
    & .container{
      flex-direction: column;
    }
  }
`;

const LookingForwardSection = styled.section`
  padding: 10vh 1em;
  & .container{
    display: flex;
  }
  @media ${breakpoints.md} {
    & .container{
      flex-direction: column;
    }
  }
`;

const ProgramExcerpt = styled(Row)`
  display: flex;
  flex-direction: column;
  
  background-color: ${(props) => props.selected ? theme.bodyBg : 'unset'};
`;

const ProgramExcerptKey = styled.div`
  display: block;
  margin-top: 0.5em;
  margin-left: 1em;
  @media ${breakpoints.md} {
    display: none;
  }
  color: ${(props) => props.selected ? theme.darkPrimary : theme.muted};
`;
const ProgramExcerptText = styled.div`
  padding: 0.5em;
  padding-top: 1em;
  padding-left: 1em;
  color: ${(props) => props.selected ? theme.darkPrimary : theme.muted};
  
  @media ${breakpoints.md} {
    & p{
      display: ${(props) => props.selected ? 'unset' : 'none'};
      /* padding-left: 2em; */
    }
    padding: 0.25em;
  }
`;

const TrainingColWrapper = styled((props) => <Link {...props} />)`
  display: block;
  text-decoration: none;
  color: ${theme.dark};
`;

const TrainingText = styled.div`
`;

const TrainingImage = styled.img`
  max-height: 25vh;
`;

const ProgramExcerptButton = styled.button`
  transition: all 0.15s ease;
  text-align: left;
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.selected && theme.bodyBg};
  
  & h6, & {
    transition: all 0.15s ease;
    color: ${(props) => props.selected ? theme.darkPrimary : theme.muted};
    cursor: ${(props) => props.selected ? 'unset' : 'pointer'}!important;
  }
  &:hover, &:hover h6{
    color: ${(props) => !props.selected && theme.primary}!important;
  }
  &:active, &:active h6{
    color: ${(props) => !props.selected && 'black'}!important;
  }
`;

const TrainingSection = styled.section``;

const WhatWeDoPage = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);
  const programs = usePrograms();
  const trainingPrograms = useTrainingPrograms();
  const [selectedProgram, setSelectedProgram] = useState();
  const flags = useFeatureFlags();

  useEffect(() => {
    if (!selectedProgram && programs) {
      setSelectedProgram(programs[0]);
    }
  }, [programs, selectedProgram]);

  return (
    <Page white={true}>
      <FeaturedHeroSection
        bgImage={HeroImage}
        breadcrumbs={true}
        content={content}
        tag="CM01"
      />
      <Container>
        <ProgramsSection>
          <Spacer height={4} />
          <h3 className="display-4 text-primary">
            Services
          </h3>
          <ArbiasButton
            onClick={() =>
            // eslint-disable-next-line max-len
              getResource('resources/Brochures/Services-Brochure.pdf')
                  .then((url) => window.open(url))}
          >
              Read Our Services Brochure &gt;
          </ArbiasButton>
          <Spacer height={4}/>
          <Row>
            <Col lg={4} md={12}>
              {programs && programs.map((program, key) => {
                return (
                  <ProgramExcerpt
                    key={key}
                    selected={program?.title === selectedProgram?.title}
                  >
                    <ProgramExcerptButton
                      className="plain-button"
                      onClick={() => setSelectedProgram(programs[key])}
                      selected={program?.title === selectedProgram?.title}
                    >
                      <ProgramExcerptKey
                        selected={program?.title === selectedProgram?.title}
                      >
                        {(key+1).toString().padStart(2, '0')}
                      </ProgramExcerptKey>
                      <ProgramExcerptText
                        selected={program?.title === selectedProgram?.title}
                      >
                        <H6 noUnderline={true}>
                          {program?.title}
                        </H6>
                        <p>{program?.excerpt}</p>
                      </ProgramExcerptText>
                    </ProgramExcerptButton>
                  </ProgramExcerpt>
                );
              })}
            </Col>
            <Col lg={8} md={12}>
              <ContentBlock
                header={(props) => <H3 {...props}/>}
                content={{
                  [selectedProgram?.index]: selectedProgram,
                }}
                tag={selectedProgram?.index}
              />
            </Col>
          </Row>
          <Spacer height={4} />
        </ProgramsSection>
      </Container>
      {flags.WhatWeDo_EnableEducation &&
      <AwarenessSection>
        <Container>
          <Spacer height={4}/>
          <ContentBlock
            className="text-center"
            header={H3}
            content={content}
            tag="CM02"
          />
          <Row>
            {['CM03', 'CM04', 'CM05'].map(
                (tag, key) => {
                  return (
                    <Col key={key} className="px-5" md={4}>
                      <ContentBlock
                        className="text-center"
                        header={(props) => <H5 noUnderline {...props}/>}
                        content={content}
                        tag={tag}
                      />
                    </Col>
                  );
                })
            }
          </Row>
        </Container>
        <Spacer height={4}/>
      </AwarenessSection>
      }
      <TrainingSection>
        <Spacer height={4}/>
        <Container>
          <ContentBlock
            header={(props) => <H3 {...props}/>}
            content={content}
            tag="CM08"
          />
          <Row>
            {trainingPrograms &&
              trainingPrograms.slice(0, 3).map((program, key) => (
                <Col key={key} lg={4} md={12}>
                  <TrainingColWrapper to={`/training${program.slug}`}>
                    <TrainingImage src={program.imageUrl} alt="TODO:" />
                    <TrainingText>
                      <H5 noUnderline>{program.title}</H5>
                      <Markdown>
                        {program.excerpt}
                      </Markdown>
                    </TrainingText>
                  </TrainingColWrapper>
                </Col>
              ))
            }
          </Row>
          <Spacer height={2} />
          <ArbiasButton to="/about/training/">
              Training &gt;
          </ArbiasButton>
        </Container>
        <Spacer height={4}/>
      </TrainingSection>
      {flags.WhatWeDo_EnableResearch &&
      <ResearchSection>
        <Spacer height={4}/>
        <Container>
          <ContentBlock
            header={(props) => <H3 {...props}/>}
            content={content}
            tag="CM09"
            linkText="Our Research"
            link={(props) => <ArbiasButton
              {...props}
              href='/about/resources#Research'
            />}
          />
        </Container>
        <Spacer height={4}/>
      </ResearchSection>
      }
      <Strip bg={theme.bodyBg} padding="4vh">
        <Spacer height={4}/>
        <Container>
          <H3>
            Understanding and managing the complexities of the Offender cohort
          </H3>
          <Row>
            <Col lg={9} md={6} sm={12}>
              <ContentBlock
                header={(props) => <></>}
                content={content}
                tag="CM10"
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <img src={managingComplexities1} />
            </Col>
          </Row>
          <Row>
            <Col lg={9} md={6} sm={12}>
              <ContentBlock
                header={(props) => <></>}
                content={content}
                tag="CM11"
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <img src={managingComplexities2} />
            </Col>
          </Row>
          <Row>
            <Col lg={9} md={6} sm={12}>
              <ContentBlock
                header={(props) => <></>}
                content={content}
                tag="CM12"
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <img src={managingComplexities3} />
            </Col>
          </Row>
        </Container>
        <Spacer height={4}/>
      </Strip>
      {flags.WhatWeDo_EnableOurHealthOurWay &&
      <OurHealthOurWaySection>
        <Container>
          <ContentBlock
            header={(props) => <H3 {...props} light={true}/>}
            content={content}
            tag="CM06"
          />
          <Tile
            title="Our Health Our Way"
            type="document"
            isRounded={true}
            light={true}
            className="text-center"
          />
        </Container>
      </OurHealthOurWaySection>
      }
      {flags.WhatWeDo_EnableLookingForward &&
      <LookingForwardSection>
        <Container>
          <ContentBlock
            header={(props) => <H3 {...props}/>}
            content={content}
            tag="CM07"
          />
          <Tile
            title="Looking Forward"
            type="document"
            isRounded={true}
            className="text-center"
          />
        </Container>
      </LookingForwardSection>
      }
    </Page>
  );
};

WhatWeDoPage.propTypes = {
  pageContext:
  PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default WhatWeDoPage;

// export const Head = () => (
//   <SEO
//     title="What We Do"
//     description="Discover how arbias can support you with NDIS Core Supports, NDIS Support Coordination, Neuropsychological Assessment and Intervention, TAC Case Management and Initial Transition Support. Our tailored services cater to those with an acquired brain injury, high complex needs, and various cultural backgrounds. Contact us for referrals or to learn more."
//   />
// );
